
import React from "react"

const Landing = ({ texte, children }) => (
  <div
    style={{
      fontSize: 80,
      textAlign: `center`,
      width:'100%',
      padding: '10% 0',
    }}
  >

    {texte}
    <div
      style={{
        fontSize:30,
        
      }}
    >
      {children}
    </div>
  </div>
  
)



export default Landing
